/* Default styling */
.bg1,
.bg2,
.bg3,
.bg4,
.bg5,
.bg6,
.bgs1,
.bgE1,
.bgE2,
.bgE3,
.bgE4,
.bgE5,
.bgE6 {
    padding: 15px 35px;
    background-color: #003E82;
    position: relative;
    z-index: auto;
    color: white;
    font-size: 1rem; /* Default font size */
}

/* Responsive adjustments */
@media screen and (max-width: 768px) {
    .bg1,
    .bg2,
    .bg3,
    .bg4,
    .bg5,
    .bg6,
    .bgs1,
    .bgE1,
    .bgE2,
    .bgE3,
    .bgE4,
    .bgE5,
    .bgE6 {
        font-size: 0.8rem; /* Adjust font size for smaller screens */
        padding:0px 15px;
    }
}
